import { useContext, useState, useEffect } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import discord from "./discord.png";
import { PlayBtn } from "./PlayBtn";
import { getRarityEmoji } from "../utils/rarity";
import { FaSquareXTwitter } from "react-icons/fa6";
export const MenuBottom = () => {
  const { state, dispatch } = useContext(AppContext);
  const [animatedText, setAnimatedText] = useState("Join us on");

  const [previousPoints, setPreviousPoints] = useState<number | null>(null);
  const [difference, setDifference] = useState<number | null>(null);

  const clickOnPlayer = async () => {
    const uid = state.player?.uid;
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    }
  };

  // Анимация текста "Join us on"
  useEffect(() => {
    const frames = [
      ">oin us on",
      ">>in us on",
      ">>>n us on",
      "J>>> us on",
      "Jo>>>us on",
      "Joi>>>s on",
      "Join>>> on",
      "Join >>>on",
      "Join u>>>n",
      "Join us>>>",
      "Join us >>",
      "Join us o>",
      "Join us on",
    ];
    let currentFrame = 0;

    const intervalId = setInterval(() => {
      setAnimatedText(frames[currentFrame]);
      currentFrame = (currentFrame + 1) % frames.length;
    }, 250);

    return () => clearInterval(intervalId);
  }, []);

  // Track points changes and show difference animation
  useEffect(() => {
    if (state.player?.points !== undefined) {
      if (previousPoints === null) {
        // Initialize previousPoints without showing difference
        setPreviousPoints(state.player.points);
      } else if (previousPoints !== state.player.points) {
        const diff = state.player.points - previousPoints;
        setDifference(diff);
        setPreviousPoints(state.player.points);

        // Hide difference after 10 seconds
        const timeoutId = setTimeout(() => {
          setDifference(null);
        }, 10000);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [state.player?.points, previousPoints]);

  return (
    <div className="fixed bottom-0 left-0 right-0 p-3">
      <div className="text-sm select-none absolute bottom-0 left-0 right-0 p-2 bg-[antiquewhite] flex justify-between items-center">
        {state.player ? (
          <div className="relative inline-block">
            <span
              onClick={clickOnPlayer}
              className="font-bold underline cursor-pointer"
            >
              {state.player.name}
            </span>{" "}
            {state.player.top3Rarities.map((rarity) => getRarityEmoji(rarity))}
            <span className="no-underline text-xs ml-2 mr-2">
              ({state.player.points})
            </span>
            <PlayBtn />
            {difference !== null && (
              <div
                className={`absolute left-14 -top-12 antique p-2 px-3 text-[16px] transition-opacity duration-700 ease-out
                ${difference > 0 ? "text-green-700" : "text-red-700"}
                animate-pointDiff`}
              >
                <div className="animate-pulse">
                  {difference > 0 ? `+${difference}` : `${difference}`}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <div className="text-xs">
          {animatedText}{" "}
          <a
            href="https://discord.gg/yqkuuXnt2N"
            target="_blank"
            rel="noreferrer"
            className="text-xs cursor-pointer select-none"
          >
            <img
              src={discord}
              alt=""
              width={20}
              className="inline-block mr-1 mt-[-3px]"
            />
            discord
          </a>{" "}
          &{" "}
          <a href="https://x.com/hexagenworld" target="_blank" rel="noreferrer">
            <FaSquareXTwitter className="inline-block text-[16px] mt-[-2px]" />
          </a>
        </div>
      </div>
    </div>
  );
};
