import { useContext, useEffect, useState } from "react";
import { AppContext } from "../State/Context";
import { Hexagen, Player } from "../types";
import useAsyncEffect from "use-async-effect";
import { askAPI, RequestMethod, showSystemMessage } from "../utils/api";
import { ActionType } from "../State/State";
import { BasicPopup } from "./BasicPopup";
import { img, openseaURL } from "../utils/settings";
import { Comments } from "../Comments/Comments";
import { getTimeAgo } from "../utils/misc";
import { RegeneratePopup } from "./RegeneratePopup";
import { LuRefreshCcw, LuRefreshCcwDot } from "react-icons/lu";
import { RarityBadge } from "../RarityBadge/RarityBadge";
import { RiNftFill, RiDeleteBin6Line } from "react-icons/ri";
import { GoHeartFill } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import { isMintingAllowed } from "../utils/nft";

export const HexInfoPopup: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const selectedHexCoord = state.selectedHexCoord;
  const [playerInfo, setPlayerInfo] = useState<Player | null>(null);
  const [ownerInfo, setOwnerInfo] = useState<Player | null>(null);
  const [views, setViews] = useState<number>(0);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [iseRegenerationPopupOpen, setIseRegenerationPopupOpen] =
    useState<boolean>(false);

  const [prompt, setPrompt] = useState(state.selectedHexInfo?.prompt || "");
  useEffect(() => {
    setPrompt(state.selectedHexInfo?.prompt || "");
  }, [state.selectedHexInfo]);

  useAsyncEffect(async () => {
    if (selectedHexCoord) {
      const hexInfo = await askAPI<Hexagen>(
        "/api/hexagen/info",
        RequestMethod.GET,
        {
          x: selectedHexCoord.x,
          y: selectedHexCoord.y,
        }
      );
      if (hexInfo) {
        setViews(hexInfo.views || 0);
        dispatch({ type: ActionType.ADD_SELECTED_HEX_INFO, hexInfo });
        const _playerInfo = await askAPI<Player>(
          `/api/player/short-info/${hexInfo.creator}`,
          RequestMethod.GET
        );

        if (_playerInfo) {
          setPlayerInfo(_playerInfo);
        }

        if (hexInfo.owner === hexInfo.creator) {
          setOwnerInfo(null);
        } else {
          const _ownerInfo = await askAPI<Player>(
            `/api/player/short-info/${hexInfo.owner}`,
            RequestMethod.GET
          );
          if (_ownerInfo) {
            setOwnerInfo(_ownerInfo);
          }
        }
      }

      const url = `/hex/${selectedHexCoord.x}/${selectedHexCoord.y}`;
      window.history.pushState(null, "", url);
    }
  }, [selectedHexCoord]);

  if (!selectedHexCoord) {
    return null;
  }

  const isNowRegenerating = state.regeneratingNowHexs.find(
    (hex) => hex.x === selectedHexCoord.x && hex.y === selectedHexCoord.y
  );

  const closePopup = () => {
    dispatch({ type: ActionType.REMOVE_SELECTED_HEX_INFO });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    window.history.pushState(null, "", "/");
  };

  const clickOnPlayer = async () => {
    const uid = playerInfo?.uid;
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    }
  };

  const clickOnOwner = async () => {
    const uid = ownerInfo?.uid;
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    }
  };

  const deleteHex = async () => {
    await askAPI<Hexagen>("/api/hexagen/delete", RequestMethod.POST, {
      x: selectedHexCoord?.x,
      y: selectedHexCoord?.y,
    });

    window.history.pushState(null, "", "/");
    window.location.reload();
  };

  const handleShare = async () => {
    if (selectedHexCoord) {
      const url = `${window.location.origin}/hex/${selectedHexCoord.x}/${selectedHexCoord.y}`;
      await navigator.clipboard.writeText(url);
      showSystemMessage({
        text: "Link copied to clipboard 🌝",
        systemMessage: "success",
      });
    }
  };

  const clickOnOpenComments = () => {
    setIsCommentsOpen(true);
  };

  const clickOnCloseComments = () => {
    setIsCommentsOpen(false);
  };

  const openRegenerationPage = () => {
    setIseRegenerationPopupOpen(true);
  };

  const mintNft = async () => {
    dispatch({ type: ActionType.TOGGLE_NFT_CONFIRM_MINTING_POPUP });
  };

  const handleUpvote = async () => {
    const hexInfo = state.selectedHexInfo;
    if (!hexInfo) {
      return;
    }
    const isSuccess = await askAPI<Hexagen>(
      "/api/upvotes/",
      RequestMethod.GET,
      {
        x: hexInfo.x,
        y: hexInfo.y,
      }
    );

    if (isSuccess) {
      dispatch({
        type: ActionType.ADD_SELECTED_HEX_INFO,
        hexInfo: {
          ...hexInfo,
          upvotes: hexInfo.upvotes + 1,
        },
      });
    }
  };

  return state.isHexInfoPopupOpen ? (
    <BasicPopup onClose={closePopup}>
      {selectedHexCoord && (
        <button
          onClick={handleShare}
          className="border-0 hover:bg-transparent absolute right-[40px] top-[18px] select-none"
        >
          <img src="/share-icon.png" alt="share" width={17} height={17} />
        </button>
      )}
      <div>
        <div className="font-bold text-lg mb-3">Hexagen Info </div>
        {state.selectedHexInfo ? (
          <div className="absolute text-right top-16 right-8 py-0 text-xs select-none cursor-pointer z-10">
            Rarity:
            <div className="pt-1">
              <RarityBadge hexagen={state.selectedHexInfo} popupAligh="right" />
              {state.selectedHexInfo.nft && (
                <div>
                  <a
                    href={openseaURL + state.selectedHexInfo.nft.tokenId}
                    target="_blank"
                    rel="noreferrer"
                    className="mt-2 flex items-center justify-end"
                  >
                    <img
                      src="/opensea-logo.png"
                      alt="OpenSea"
                      className="w-4 h-4 mr-1"
                    />
                    NFT
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {state.selectedHexInfo ? (
          <>
            x:{state.selectedHexInfo.x} y:
            {state.selectedHexInfo.y}
            <div className="select-none">
              <FaEye className="mr-1 inline text-[16px] translate-y-[-1px]" />
              {views}
            </div>
            <div className="relative">
              {isNowRegenerating ? (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-9xl text-white spin">
                    <LuRefreshCcwDot />
                  </div>
                </div>
              ) : null}
              <a
                href={img(
                  `x${state.selectedHexInfo.x}y${state.selectedHexInfo.y}@big.png`
                )}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={img(
                    `x${state.selectedHexInfo.x}y${state.selectedHexInfo.y}@big.png?v=${state.selectedHexInfo.regeneratedAt}`
                  )}
                  alt="hexagen"
                  className="mt-[-60px] mb-[-20px] min-h-[300px] max-h-[400px] mx-auto"
                />
              </a>
              <div
                onClick={handleUpvote}
                className="absolute bottom-2 left-2 button px-4 py-1 text-xs select-none cursor-pointer"
              >
                <GoHeartFill className="mr-1 inline text-[14px] translate-y-[-1px]" />
                upvote | {state.selectedHexInfo.upvotes}
              </div>
              <div
                onClick={clickOnOpenComments}
                className="absolute bottom-2 right-2 button px-2 py-1 text-xs select-none cursor-pointer"
              >
                comments | {state.selectedHexInfo.commentsNum}
              </div>
            </div>
            <div className="text-center mb-4 mt-4">
              <div className="pt-4">
                <div className="font-bold">Prompt:</div>{" "}
                <div className="antique text-center px-2 py-1 w-full whitespace-pre-wrap">
                  {prompt}
                </div>
              </div>

              <div className="flex justify-center">
                <div className="flex items-center">
                  <div className="text-[40px] mr-2">
                    {state.selectedHexInfo.points}
                  </div>
                  <div className="flex flex-col justify-center leading-tight">
                    <span className="text-left">creative</span>
                    <span className="text-left">points</span>
                  </div>
                </div>
              </div>

              <div className="mb-4 text-xs px-3 opacity-60">
                Author receives a bonus for each upvote.
                <br />
                Bonus size depends on hexagon's value and rarity.
              </div>

              <div onClick={clickOnPlayer} className="mb-3 text-xs">
                Author:
                <span className="cursor-pointer underline font-bold">
                  {playerInfo ? playerInfo.name : state.selectedHexInfo.creator}
                </span>
              </div>
              {ownerInfo ? (
                <div className="text-xs mb-3" onClick={clickOnOwner}>
                  Owner:
                  <span className="cursor-pointer underline font-bold">
                    {ownerInfo.name}
                  </span>
                </div>
              ) : null}
              <div className="text-xs">
                Created
                {" " +
                  getTimeAgo(
                    new Date(state.selectedHexInfo.createdAt).toISOString()
                  )}
              </div>
              <div className="flex items-center justify-center mt-6 mb-4 w-full">
                {state.player?.uid === state.selectedHexInfo.owner ? (
                  <button
                    className="button mb-1 px-2 py-1 ml-1 text-xs"
                    onClick={openRegenerationPage}
                  >
                    <LuRefreshCcw className="mr-1 inline text-[14px] translate-y-[-1px]" />
                    Regenerate
                  </button>
                ) : null}
                {state.player?.role === "admin" ? (
                  <button
                    className="button mb-1 px-2 py-1 ml-1 text-xs"
                    onClick={deleteHex}
                  >
                    <RiDeleteBin6Line className="mr-1 inline text-[14px] translate-y-[-1px]" />
                    Delete
                  </button>
                ) : null}
                {state.player?.uid === state.selectedHexInfo.owner &&
                state.selectedHexInfo.nft === null &&
                isMintingAllowed(state.player, state.selectedHexInfo) ? (
                  <>
                    <button
                      className="button mb-1 px-2 py-1 ml-1 text-xs"
                      onClick={mintNft}
                    >
                      <RiNftFill className="mr-1 inline text-[14px] translate-y-[-1px]" />
                      Mint NFT
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <div>Loading info...</div>
        )}
      </div>
      {isCommentsOpen && state.selectedHexInfo && (
        <div className="absolute top-0 left-0 right-0 bottom-0 antique p-4 z-20">
          <div
            onClick={clickOnCloseComments}
            className="select-none cursor-pointer button w-20 p-1 px-2 text-center text-xs mb-4"
          >
            {"<<< "}back
          </div>
          <Comments x={state.selectedHexInfo.x} y={state.selectedHexInfo.y} />
        </div>
      )}

      {iseRegenerationPopupOpen && (
        <RegeneratePopup closeFn={() => setIseRegenerationPopupOpen(false)} />
      )}
    </BasicPopup>
  ) : null;
};
