import { Hexagen } from "../types";
import { Player } from "../types";
import { getHexagenRarity } from "./rarity";

import { rarityLevels } from "./rarity";

export const isMintingAllowed = (player: Player, hexagen: Hexagen) => {
  const nftTotalNumber = player.nftsNumber;
  const isEnoughNfts = nftTotalNumber < 12;

  const playerPoints = player.points;
  const isEnoughPoints = playerPoints > 240;

  const hexagenRarity = getHexagenRarity(hexagen);
  const isAllowedRarity = rarityLevels.indexOf(hexagenRarity.rarity) >= 3;

  const isWalletConnected = player.crypto;

  const isPlayerOwner = hexagen.owner === player.uid;

  const isMintingAllowedFromAdmin =
    player.role === "admin" && isPlayerOwner && isAllowedRarity;

  const isAllowed =
    isEnoughNfts &&
    isEnoughPoints &&
    isAllowedRarity &&
    isWalletConnected &&
    isPlayerOwner;

  return isAllowed || isMintingAllowedFromAdmin;
};
