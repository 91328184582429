import { useState, useEffect } from "react";
import { RequestMethod, askAPI } from "../utils/api";
import { useContext } from "react";
import { ActionType } from "../State/State";
import { BasicPopup } from "./BasicPopup";
import { AppContext } from "../State/Context";
import { Hexagen } from "../types";
import { getTimeAgo } from "../utils/misc";
import { RarityBadge } from "../RarityBadge/RarityBadge";
import { img, openseaURL } from "../utils/settings";
import { hexWidth, hexHeight } from "../State/State";

enum TimeRange {
  WEEK = "WEEK",
  MONTH = "MONTH",
  ALL_TIME = "ALL_TIME",
  NEW_NFT = "NEW_NFT",
}

const TIME_RANGE_OPTIONS = [
  { value: TimeRange.WEEK, label: "most rare last week" },
  { value: TimeRange.MONTH, label: "most rare last month" },
  { value: TimeRange.ALL_TIME, label: "most rare all time" },
  { value: TimeRange.NEW_NFT, label: "new NFTs" },
] as const;

export const HexagonCard = ({
  hexagen,
  onClick,
}: {
  hexagen: Hexagen;
  onClick: (hexagen: Hexagen) => void;
}) => (
  <div className="flex min-h-[160px] my-4">
    <img
      src={img(`x${hexagen.x}y${hexagen.y}.png`)}
      alt="hexagen"
      width={160}
      onClick={() => onClick(hexagen)}
      className="cursor-pointer h-[160px]"
    />
    <div className="text-xs pl-6 pt-4 flex-1">
      <div className="flex items-center mb-2">
        <RarityBadge hexagen={hexagen} />
        {hexagen.nft && (
          <div className="mb-1 ml-5 text-gray-700">
            <a
              href={openseaURL + hexagen.nft.tokenId}
              target="_blank"
              rel="noreferrer"
              className="mt-2 flex items-center justify-end"
            >
              <img
                src="/opensea-logo.png"
                alt="OpenSea"
                className="w-4 h-4 mr-1"
              />
              NFT
            </a>
          </div>
        )}
      </div>
      <div className="mt-3 mb-2 break-words">
        <span className="text-sm">{hexagen.prompt}</span>
      </div>
      <div className="text-xs opacity-60">
        {hexagen.x}/{hexagen.y} | {hexagen.points}pts | 👁 {hexagen.views} |{" "}
        {getTimeAgo(new Date(hexagen.createdAt).toISOString())}
      </div>
    </div>
  </div>
);

export const RarityRatingPopup = () => {
  const { dispatch, state } = useContext(AppContext);
  const [mostRareHexagons, setMostRareHexagons] = useState<Hexagen[]>([]);
  const [timeRange, setTimeRange] = useState<TimeRange>(TimeRange.WEEK);

  const fetchMostRareHexagons = async () => {
    const endpoints = {
      [TimeRange.WEEK]: "/api/hexagen/most-rare-last-week",
      [TimeRange.MONTH]: "/api/hexagen/most-rare-last-month",
      [TimeRange.ALL_TIME]: "/api/hexagen/most-rare-all-time",
      [TimeRange.NEW_NFT]: "/api/hexagen/new-nfts",
    };

    const endpoint = endpoints[timeRange];
    const hexagons = await askAPI<Hexagen[]>(endpoint, RequestMethod.GET);
    if (hexagons) {
      setMostRareHexagons(hexagons);
    }
  };

  useEffect(() => {
    fetchMostRareHexagons();
  }, [timeRange]);

  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
  };

  const navigateToHexagon = (hexagen: Hexagen) => {
    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -(hexagen.x - 1) * hexWidth * state.worldScale,
      y: -(hexagen.y - 3) * hexHeight * state.worldScale,
    });
  };

  return (
    <BasicPopup onClose={closePopup}>
      <div className="font-bold text-lg mb-6">Rarity Rating 🔥</div>
      <div className="text-xs space-y-2 overflow-hidden pt-4">
        <p>
          Rarity is influenced by the creation time, creative point rating,
          number of views, and upvotes. Over time, this leads to an increase in
          rarity.
        </p>
        <p>
          However, the formula for calculating rarity is still being adjusted
          and may change in the future, which could cause some hexagons to lose
          their rarity.
        </p>

        <div className="flex justify-between py-8 overflow-hidden">
          <div className="flex justify-center max-w-[350px] mx-auto">
            {TIME_RANGE_OPTIONS.map(({ value, label }) => (
              <div
                key={value}
                className={`px-2 text-center cursor-pointer select-none underline hover:no-underline ${
                  timeRange === value ? "no-underline" : ""
                }`}
                onClick={() => setTimeRange(value)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 px-4 overflow-hidden">
          {mostRareHexagons.map((hexagen, index) => (
            <HexagonCard
              key={index}
              hexagen={hexagen}
              onClick={navigateToHexagon}
            />
          ))}
        </div>
      </div>
    </BasicPopup>
  );
};
