import { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";
import { web3Service } from "../utils/web3Service";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { RiDeleteBin6Line, RiNftFill } from "react-icons/ri";
import { RequestMethod } from "../utils/api";
import { askAPI } from "../utils/api";
import { hexWidth, hexHeight } from "../State/State";

export const NFTPopup = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [walletAddress, setWalletAddress] = useState<string | null>(
    state.player?.crypto?.walletAddress || null
  );

  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_NFT_POPUP });
  };

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const onClaimNFT = async () => {
    const result = await askAPI(`/api/nft/claim`, RequestMethod.POST, { x, y });

    if (result) {
      dispatch({ type: ActionType.SELECT_HEX, x, y });
      dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
      dispatch({
        type: ActionType.CHANGE_WORLD_COORDINATES,
        x: -x * hexWidth * state.worldScale,
        y: -(y - 2) * hexHeight * state.worldScale,
        logggable: true,
      });
    }
  };

  const onConnectWallet = async () => {
    try {
      setIsConnecting(true);
      setError(null);

      // Connect wallet
      const { address } = await web3Service.connectWallet();

      // Verify ownership
      await web3Service.verifyWalletOwnership();

      setWalletAddress(address);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to connect wallet");
    } finally {
      setIsConnecting(false);
    }
  };

  const onDeleteWallet = async () => {
    await web3Service.deleteWallet();
    setWalletAddress(null);
  };

  return (
    <BasicPopup onClose={closePopup}>
      <div className="font-bold text-lg mb-3">
        <RiNftFill className="text-[25px] inline-block mr-2" />
        NFTs
      </div>

      <div className="pt-8 max-w-[400px] ">
        For all officially released NFTs, visit our{" "}
        <a
          href="https://opensea.io/collection/hexagen-world"
          target="_blank"
          rel="noreferrer"
          className="text-blue-900 "
        >
          Collection on OpenSea
        </a>
        .
      </div>

      <div className="text-sm pt-8 opacity-80">
        You can get a free NFT when:
        <ul className="list-disc pl-4 mt-2 [&>li]:mb-2">
          <li>
            You have <b>less than 12 NFTs</b> in your collection
          </li>
          <li>
            You need to have enough balance. <br />
            More than <b>240</b> creative points
          </li>
          <li>
            Your Hexagen is of <b>Epic or higher rarity</b>
          </li>
          <li>
            You have a <b>connected wallet</b>
          </li>
        </ul>
      </div>

      <div className="mt-7">
        ‼️ NFT minting is free for players but{" "}
        <b>requires a connected wallet</b> to specify the owners address.
      </div>
      {state.player ? (
        <>
          {walletAddress ? (
            <div>
              <div className="mt-8">Wallet connected:</div>
              <div className="text-sm break-all">
                <span className="text-xs">{walletAddress}</span>
                <span
                  onClick={onConnectWallet}
                  className="text-xl inline-block mb-[-5px] pl-2 cursor-pointer"
                >
                  <IoRefreshCircleOutline />
                </span>
                <span
                  onClick={onDeleteWallet}
                  className="text-xl inline-block mb-[-5px] pl-2 cursor-pointer"
                >
                  <RiDeleteBin6Line />
                </span>
              </div>
              <div className="mt-8 mb-10">
                Claim your Hexagen from NFTs:
                <div className="text-xs max-w-[440px] py-2 pb-1 opacity-80">
                  Enter the X and Y coordinates of your NFT to verify ownership
                  and instantly claim the corresponding in-game hexagen.
                </div>
                <div className="flex items-center gap-2 mt-2 text-xs">
                  <div className="flex items-center">
                    <span className="mr-1">X:</span>
                    <input
                      type="number"
                      className="w-16 pl-3 py-1 border border-gray-300"
                      placeholder="0"
                      onChange={(e) => setX(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="mr-1">Y:</span>
                    <input
                      type="number"
                      className="w-16 pl-3 py-1 border border-gray-300"
                      placeholder="0"
                      onChange={(e) => setY(parseInt(e.target.value))}
                    />
                  </div>
                  <button
                    onClick={onClaimNFT}
                    className="px-3 py-1 text-xs border border-black hover:bg-black hover:text-white"
                  >
                    Claim NFT
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
              <div
                onClick={!isConnecting ? onConnectWallet : undefined}
                className={`mt-6 mb-6 cursor-pointer select-none border border-black border-solid px-2 py-1 w-fit ${
                  !isConnecting
                    ? "hover:bg-black hover:text-white"
                    : "opacity-50 cursor-not-allowed"
                }`}
              >
                {isConnecting ? "Connecting..." : "Connect 🦊 MetaMask Wallet"}
              </div>
            </div>
          )}
          <div className="mt-4 pb-4">
            <div className="text-xs opacity-80">
              By connecting your wallet, you agree to the{" "}
              <a href="/tos.txt" target="_blank">
                Terms of Service
              </a>
              .
            </div>
          </div>
        </>
      ) : (
        <div className="">
          Please{" "}
          <span
            onClick={() => dispatch({ type: ActionType.TOGGLE_LOGIN_POPUP })}
            className="underline cursor-pointer"
          >
            sign in
          </span>{" "}
          to connect your 🦊 MetaMask wallet.
        </div>
      )}
    </BasicPopup>
  );
};
