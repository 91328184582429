import { useContext, useEffect, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ActionType, hexHeight, hexWidth } from "../State/State";
import { Hexagon } from "./Hexagon";
import "./World.scss";
import { AppContext } from "../State/Context";

export const World = () => {
  const { state, dispatch } = useContext(AppContext);
  const [hexEllements, setHexElements] = useState<JSX.Element[]>([]);

  const isDragging = state.isWorldDragging;

  const generatingNowHexs = state.generatingNowHexs;

  useEffect(() => {
    const handleResize = () => {
      setHexElements(
        generateHexes(state.worldX, state.worldY, state.worldScale)
      );
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.worldX, state.worldY, state.worldScale, generatingNowHexs]);

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    const x = Math.round(data.x);
    const y = Math.round(data.y);

    requestAnimationFrame(() =>
      dispatch({ type: ActionType.STOP_WORLD_DRAGGING, logggable: false })
    );

    setHexElements(generateHexes(x, y, state.worldScale));

    const isCoordinateUpdated = state.worldX !== x || state.worldY !== y;
    if (isCoordinateUpdated) {
      dispatch({
        type: ActionType.CHANGE_WORLD_COORDINATES,
        x,
        y,
      });
    }
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    const roundTo = 5;
    const x = Math.round(data.x / roundTo);
    const y = Math.round(data.y / roundTo);
    const stateWorldX = Math.round(state.worldX / roundTo);
    const stateWorldY = Math.round(state.worldY / roundTo);

    if (!isDragging && (stateWorldX !== x || stateWorldY !== y)) {
      dispatch({ type: ActionType.START_WORLD_DRAGGING, logggable: false });
    }
  };

  return (
    <div className="wrapper absolute top-0 bottom-0 left-0 right-0 z-0 bg-black">
      <Draggable
        onStop={handleDragStop}
        onDrag={handleDrag}
        position={{ x: state.worldX, y: state.worldY }}
      >
        <div className="field absolute z-10 cursor-grab active:cursor-grabbing ">
          {hexEllements}
        </div>
      </Draggable>
    </div>
  );
};

const generateHexes = (startX: number, startY: number, worldScale: number) => {
  const hexGapX = Math.floor(startX / (hexWidth * worldScale)) + 2;
  const hexGapY = Math.floor(startY / (hexHeight * worldScale)) + 1;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight + 100;

  const numHorizontalHexes =
    Math.ceil(windowWidth / (hexWidth * worldScale)) + 2;
  const numVerticalHexes =
    Math.ceil(windowHeight / (hexHeight * worldScale)) + 1;

  return Array.from({
    length: numHorizontalHexes * numVerticalHexes,
  }).map((_, i) => {
    const x = (i % numHorizontalHexes) - hexGapX;
    const y = Math.floor(i / numHorizontalHexes) - hexGapY;

    return <Hexagon key={x + "#" + y} x={x} y={y} />;
  });
};
