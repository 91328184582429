import { useContext } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { rootURL } from "../utils/settings";
import { isInIframe } from "../utils/misc";
import { IoMdMenu } from "react-icons/io";

export const MenuBtn = () => {
  const { dispatch, state } = useContext(AppContext);

  if (isInIframe()) {
    return null;
  }

  const toggleMenu = () => {
    if (state.player) {
      dispatch({ type: ActionType.TOGGLE_MENU_POPUP });
    } else {
      const isLocalTosAccepted = localStorage.getItem("tos") === "true";
      if (isLocalTosAccepted) {
        window.location.href = rootURL + "/api/auth/google/";
      } else {
        dispatch({ type: ActionType.TOGGLE_LOGIN_POPUP });
      }
    }
  };

  return (
    <div
      onClick={toggleMenu}
      className="cursor-pointer text-right pr-3 hover:underline"
    >
      {state.player ? <IoMdMenu className="text-[20px]" /> : "Login 👤"}
    </div>
  );
};
