import { useState } from "react";
import { Hexagen } from "../types";
import { getHexagenRarity } from "../utils/rarity";

interface RarityBadgeProps {
  hexagen: Hexagen;
  popupAligh?: "left" | "right";
}

export const RarityBadge = ({
  hexagen,
  popupAligh = "left",
}: RarityBadgeProps) => {
  const rarityInfo = getHexagenRarity(hexagen);

  const [isRarityInfoOpen, setIsRarityInfoOpen] = useState(false);

  return (
    <div>
      <div
        className="relative flex items-center border border-opacity-20 border-black mt-1 px-2 pr-2 py-[2px] cursor-pointer antique"
        onMouseEnter={() => setIsRarityInfoOpen(true)}
        onMouseLeave={() => setIsRarityInfoOpen(false)}
      >
        <div className="text-2xl absolute left-[-8px] top-[-6px]">
          {rarityInfo.emoji}{" "}
        </div>
        <div className="text-xs pl-[12px]"> {rarityInfo.rarity}</div>

        <div
          className={`select-none text-left pointer-events-none transition-opacity duration-300 absolute top-7 border border-gray-600 ${
            popupAligh === "left" ? "left-0" : "right-0"
          } px-2 pl-4 py-4 text-xs z-50 antique whitespace-nowrap ${
            isRarityInfoOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          <ul>
            {[
              { emoji: "🍂", name: "Common" },
              { emoji: "🌿", name: "Uncommon" },
              { emoji: "💎", name: "Rare" },
              { emoji: "⚔️", name: "Epic" },
              { emoji: "🏆", name: "Legendary" },
              { emoji: "🦄", name: "Mythic" },
              { emoji: "👑", name: "Divine" },
              { emoji: "🚀", name: "Cosmic" },
              { emoji: "🌟", name: "Transcendent" },
              { emoji: "🔥", name: "Ultimate" },
            ]
              .reverse()
              .map(({ emoji, name }) => (
                <li
                  key={name}
                  className={
                    rarityInfo?.rarity === name.toLowerCase()
                      ? " font-bold text-sm py-1 pl-2"
                      : "py-1"
                  }
                >
                  {emoji} {name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
