import { ethers } from "ethers";

// Types for our service
export interface Web3Provider {
  provider: ethers.providers.Web3Provider | null;
  signer: ethers.providers.JsonRpcSigner | null;
  address: string | null;
}

class Web3Service {
  private provider: ethers.providers.Web3Provider | null = null;
  private signer: ethers.providers.JsonRpcSigner | null = null;
  private address: string | null = null;

  async connectWallet(): Promise<Web3Provider> {
    try {
      // Check if MetaMask is installed
      if (!window.ethereum) {
        throw new Error("Please install MetaMask to connect your wallet");
      }

      // Create a new Web3Provider
      this.provider = new ethers.providers.Web3Provider(window.ethereum);

      // Request account access
      await this.provider.send("eth_requestAccounts", []);

      // Get the signer
      this.signer = this.provider.getSigner();

      // Get the address
      this.address = await this.signer.getAddress();

      return {
        provider: this.provider,
        signer: this.signer,
        address: this.address,
      };
    } catch (error) {
      console.error("Error connecting wallet:", error);
      throw error;
    }
  }

  async signMessage(message: string): Promise<string> {
    if (!this.signer) {
      throw new Error("Wallet not connected");
    }

    try {
      const signature = await this.signer.signMessage(message);
      return signature;
    } catch (error) {
      console.error("Error signing message:", error);
      throw error;
    }
  }

  async verifyWalletOwnership(): Promise<boolean> {
    try {
      if (!this.address || !this.signer) {
        throw new Error("Wallet not connected");
      }

      // Get nonce from backend
      const response = await fetch(`/api/nft/nonce?address=${this.address}`);
      const { nonce } = await response.json();

      // Sign the nonce
      const signature = await this.signMessage(nonce);

      // Verify signature on backend
      const verifyResponse = await fetch("/api/nft/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: this.address,
          signature,
        }),
      });

      const result = await verifyResponse.json();
      return result.success;
    } catch (error) {
      console.error("Error verifying wallet ownership:", error);
      throw error;
    }
  }

  async deleteWallet(): Promise<void> {
    await fetch("/api/nft/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAddress(): string | null {
    return this.address;
  }

  isConnected(): boolean {
    return !!this.address && !!this.signer;
  }
}

export const web3Service = new Web3Service();
