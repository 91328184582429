import { useContext, useEffect, useState } from "react";
import { Moon } from "../Misc/Moon";
import { ActionType } from "../State/State";
import { img } from "../utils/settings";
import { hexWidth, hexHeight } from "../State/State";
import { askAPI, RequestMethod, showSystemMessage } from "../utils/api";
import { Hexagen, HexagenStatus } from "../types";
import { AppContext } from "../State/Context";
import { LuRefreshCcwDot } from "react-icons/lu";
import { getAltitude } from "../utils/misc";
import useAsyncEffect from "use-async-effect";

interface HexagonProps {
  x: number;
  y: number;
}

export const getGenerationPenalty = (neighboursNumber: number) => {
  switch (neighboursNumber) {
    case 0:
      return -1;
    case 1:
      return -240;
    case 2:
      return -150;
    case 3:
      return -40;
    case 4:
      return -20;
    case 5:
      return 0;
    case 6:
      return 50;
    default:
      return -100;
  }
};

export const findNeighboursNumber = (
  x: number,
  y: number,
  booleanMap: { [key: string]: boolean }
): number => {
  const evenRowGap = y % 2 === 0 ? 1 : 0;
  const neighbors = [
    { x: x - evenRowGap, y: y - 1 },
    { x: x + (1 - evenRowGap), y: y - 1 },
    { x: x - 1, y },
    { x: x + 1, y },
    { x: x - evenRowGap, y: y + 1 },
    { x: x + (1 - evenRowGap), y: y + 1 },
  ];

  let neighboursCount = 0;

  for (const neighbor of neighbors) {
    const key = `${neighbor.x}-${neighbor.y}`;
    if (booleanMap[key]) {
      neighboursCount++;
    }
  }

  return neighboursCount;
};

export const Hexagon: React.FC<HexagonProps> = ({ x, y }) => {
  const { state, dispatch } = useContext(AppContext);

  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const [backgroundImage, setBackgroundImage] = useState<string>(
    img(`x${x}y${y}.png?${Date.now()}`)
  );

  const altitude = getAltitude(x, y);
  const altitudeClass = `hex-bg${altitude}`;

  const [zIndex, setZIndex] = useState<number>(0);

  const isNowGenerating = state.generatingNowHexs.some(
    (hex) => hex.x === x && hex.y === y
  );

  const isNowRegenerating = state.regeneratingNowHexs.some(
    (hex) => hex.x === x && hex.y === y
  );

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const evenGap = y % 2 ? (hexWidth * state.worldScale) / 2 : 0;

  const isDragging = state.isWorldDragging;

  const booleanMap = state.booleanMap;
  // find neibours number
  const neighboursNumber = findNeighboursNumber(x, y, booleanMap);

  const onClickOnHex = async () => {
    if (!isDragging) {
      dispatch({ type: ActionType.SELECT_HEX, x, y });
      if (state.player) {
        if (isEmpty) {
          dispatch({ type: ActionType.TOGGLE_HEX_GENERATE_POPUP });
        } else {
          dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
        }
      } else {
        dispatch({ type: ActionType.TOGGLE_LOGIN_POPUP });
      }
    }
  };

  useAsyncEffect(async () => {
    const randomPause = Math.floor(Math.random() * 50);
    await new Promise((resolve) => setTimeout(resolve, randomPause));

    const response = await fetch(backgroundImage);
    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
      return;
    }

    const date = new Date(response.headers.get("last-modified") || 0);
    setZIndex(date.getTime() / 1000 - 1700000000);

    const blob = await response.blob();

    const base64data = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = console.log;
      reader.readAsDataURL(blob);
    });

    const img = new Image();
    img.onload = () => {
      if (img.width !== 1) {
        setIsEmpty(false);
        dispatch({ type: ActionType.ADD_TO_BOOLEAN_MAP, x, y });
      }
      setIsLoaded(true);
    };
    img.onerror = console.log;
    img.src = base64data;
  }, [backgroundImage]);

  useEffect(() => {
    let timer: any;
    const check = async () => {
      // get hex info from backend
      const hexInfo = await askAPI<Hexagen>(
        "/api/hexagen/info",
        RequestMethod.GET,
        {
          x,
          y,
        }
      );
      if (hexInfo) {
        console.log(`status | x${x} y${y} | ${hexInfo.status}`);

        if (hexInfo.status === HexagenStatus.GENERATING) {
          setIsEmpty(false);
          timer = setTimeout(check, 1000);
        } else {
          setBackgroundImage(img(`x${x}y${y}.png?${Date.now()}`));
          setZIndex(Math.round(Date.now() / 1000 - 1700000000));
          dispatch({
            type: ActionType.REMOVE_GENERATING_NOW_HEX,
            x,
            y,
          });
          dispatch({
            type: ActionType.TOGGLE_GENERATED_AWARD_POPUP,
            selectedHexInfo: hexInfo,
          });
          dispatch({
            type: ActionType.ADD_TO_BOOLEAN_MAP,
            x,
            y,
          });
        }
      } else {
        dispatch({
          type: ActionType.REMOVE_GENERATING_NOW_HEX,
          x,
          y,
        });
        setIsEmpty(true);
        showSystemMessage({
          text: "😢 Ouch! Sorry. Failed to generate hexagen. Please try again.",
          systemMessage: "error",
        });
      }
    };

    if (isNowGenerating) {
      check();
      console.log(`start generating | x${x} y${y}`);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isNowGenerating]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const check = async () => {
      console.log(`regenerating check | x${x} y${y}`);
      const hexInfo = await askAPI<Hexagen>(
        "/api/hexagen/info",
        RequestMethod.GET,
        {
          x,
          y,
        }
      );

      if (hexInfo?.status && hexInfo?.status === HexagenStatus.READY) {
        setBackgroundImage(img(`x${x}y${y}.png?${Date.now()}`));
        setZIndex(Math.round(Date.now() / 1000 - 1700000000));
        dispatch({
          type: ActionType.REMOVE_REGENERATING_NOW_HEX,
          x,
          y,
        });

        if (state.isHexInfoPopupOpen) {
          dispatch({
            type: ActionType.TOGGLE_HEX_INFO_POPUP,
          });
          requestAnimationFrame(() => {
            dispatch({
              type: ActionType.TOGGLE_HEX_INFO_POPUP,
            });
          });
        }
      }
    };

    if (isNowRegenerating) {
      timer = setInterval(check, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isNowRegenerating]);

  const imageStyles: React.CSSProperties = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
  };

  const penaltyText =
    neighboursNumber === 0
      ? ""
      : `${getGenerationPenalty(neighboursNumber)} pts`;
  const coordsOpacity = neighboursNumber ? "opacity-90" : "opacity-45";
  const coordsTextColor = altitude < 3 ? "text-blue-300" : "text-black";

  return (
    <div
      key={x + "#" + y + isNowGenerating}
      className={`hex transition-opacity duration-100 absolute w-[256px] h-[256px] hex ${altitudeClass} ${
        !isLoaded && "opacity-0"
      }`}
      style={{
        width: `${256 * state.worldScale}px`,
        height: `${261 * state.worldScale}px`,
        left: `${x * (hexWidth * state.worldScale) + evenGap}px`,
        top: `${y * (hexHeight * state.worldScale) - hexHeight / 2}px`,
        zIndex: zIndex,
      }}
    >
      <div
        className={`${
          !neighboursNumber && "pointer-events-none"
        } absolute top-10 bottom-10 left-10 right-10 flex items-center justify-center text-center`}
        onClick={onClickOnHex}
        onTouchEnd={onClickOnHex}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {isNowRegenerating ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-5xl text-white spin z-[9999999]">
              <LuRefreshCcwDot />
            </div>
          </div>
        ) : null}
        {!isEmpty && isNowGenerating ? (
          <Moon />
        ) : isMouseOver && neighboursNumber ? (
          <div
            className={`cursor-pointer text-xs pl-1 pt-3 pb-2 ${coordsTextColor}`}
          >
            Claim me
            <br />
            for
            <br />
            {getGenerationPenalty(neighboursNumber)} pts!
          </div>
        ) : (
          <div
            className={`text-xs ${
              neighboursNumber && "cursor-pointer"
            } p-4  ${coordsOpacity} ${coordsTextColor}`}
          >
            {neighboursNumber ? (
              <div className={`text-[10px]`}>
                x:{x} y:{y}
              </div>
            ) : null}
            {penaltyText}
          </div>
        )}
      </div>
      <div
        className="absolute pointer-events-none top-0 bottom-0 left-0 right-0"
        style={imageStyles}
      ></div>
    </div>
  );
};
