import React, { useState, useRef, useEffect, useContext } from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { hexWidth, hexHeight } from "../State/State";

// Constants for the map
const MAP_WIDTH = 3900;
const MAP_HEIGHT = 2300;
const MIN_X = -280;
const MAX_X = 350;
const MIN_Y = -170;
const MAX_Y = 150;

// Current position marker component
const CurrentPositionMarker: React.FC<{
  x: number;
  y: number;
}> = ({ x, y }) => {
  return (
    <div
      className="absolute transform -translate-x-1/2 -translate-y-1/2"
      style={{ left: x, top: y }}
    >
      <div className="w-10 h-6 border-2 border-white"></div>
    </div>
  );
};

export const MiniMap: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [mouseDownCoords, setMouseDownCoords] = useState({ x: 0, y: 0 });

  const nodeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isMapOpen && containerRef.current && imageRef.current) {
      const container = containerRef.current.getBoundingClientRect();
      const image = imageRef.current.getBoundingClientRect();

      const newBounds = {
        left: container.width - image.width - 50,
        top: container.height - image.height - 120,
        right: 0,
        bottom: 0,
      };

      setBounds(newBounds);

      // Get current marker position
      const currentPos = getCurrentPosition();

      // Calculate position to center the marker
      const centerX = container.width / 2 - currentPos.x - 150;
      const centerY = container.height / 2 - currentPos.y - 60;

      // Clamp the position within bounds
      const clampedX = Math.max(
        newBounds.left,
        Math.min(newBounds.right, centerX)
      );
      const clampedY = Math.max(
        newBounds.top,
        Math.min(newBounds.bottom, centerY)
      );

      setPosition({ x: clampedX, y: clampedY });
    }
  }, [isMapOpen]);

  const clickOnMapBg = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsMapOpen(false);
    }
  };

  const handleDragStop = (e: any, data: DraggableData) => {
    const x = Math.round(data.x);
    const y = Math.round(data.y);

    let coordinates: { x: number; y: number } = { x: 0, y: 0 };

    if ("clientX" in e) {
      coordinates = { x: e.clientX, y: e.clientY };
    } else if (e.type === "touchend" && e.changedTouches) {
      coordinates = {
        x: Math.floor(e.changedTouches[0].clientX),
        y: Math.floor(e.changedTouches[0].clientY),
      };
    }

    const isClick =
      Math.abs(coordinates.x - mouseDownCoords.x) < 2 &&
      Math.abs(coordinates.y - mouseDownCoords.y) < 2;

    setPosition({ x, y });

    if (isClick) {
      const cellsNumberX = MAX_X - MIN_X;
      const cellsNumberY = MAX_Y - MIN_Y;

      let clickX: number, clickY: number;

      if ("offsetX" in e) {
        clickX = (e as MouseEvent).offsetX;
        clickY = (e as MouseEvent).offsetY;
      } else if (e.type === "touchend" && e.changedTouches) {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        clickX = e.changedTouches[0].clientX - rect.left;
        clickY = e.changedTouches[0].clientY - rect.top;
      } else {
        return;
      }

      const percentX = clickX / MAP_WIDTH;
      const percentY = clickY / MAP_HEIGHT;

      const xCellCoord = Math.floor(percentX * cellsNumberX) + MIN_X;
      const yCellCoord = Math.floor(percentY * cellsNumberY) + MIN_Y;

      dispatch({
        type: ActionType.CHANGE_WORLD_COORDINATES,
        x: -(xCellCoord - 2) * hexWidth * state.worldScale,
        y: -(yCellCoord - 2) * hexHeight * state.worldScale,
      });

      setIsMapOpen(false);
    }
  };

  // Calculate current position for the marker
  const getCurrentPosition = () => {
    const cellsNumberX = MAX_X - MIN_X;
    const cellsNumberY = MAX_Y - MIN_Y;

    // Convert world coordinates to map position
    const currentX = -state.worldX / (hexWidth * state.worldScale) + 2;
    const currentY = -state.worldY / (hexHeight * state.worldScale) + 2;

    // Convert to percentage
    const percentX = (currentX - MIN_X) / cellsNumberX;
    const percentY = (currentY - MIN_Y) / cellsNumberY;

    return {
      x: percentX * MAP_WIDTH,
      y: percentY * MAP_HEIGHT,
    };
  };

  return (
    <div className="fixed top-16 right-16 text-2xl cursor-pointer">
      <FaMapMarkedAlt
        className="antique-font-hover antique p-1 w-8 h-7"
        onClick={() => setIsMapOpen(true)}
      />
      {isMapOpen && (
        <div>
          <div
            ref={containerRef}
            className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 overflow-hidden"
            onClick={clickOnMapBg}
          ></div>
          <div className="fixed top-16 left-6 bottom-20 right-6 overflow-hidden shadow-lg">
            <Draggable
              nodeRef={nodeRef}
              bounds={bounds}
              position={position}
              onStop={handleDragStop}
              onStart={(e: DraggableEvent) => {
                let coordinates: { x: number; y: number } = { x: 0, y: 0 };

                if ("clientX" in e) {
                  coordinates = { x: e.clientX, y: e.clientY };
                } else if (e.type === "touchstart" && e.touches) {
                  coordinates = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                  };
                }
                setMouseDownCoords(coordinates);
              }}
            >
              <div ref={nodeRef} className="cursor-move_disabled relative">
                <img
                  ref={imageRef}
                  src="/map.jpg"
                  alt="map"
                  className="w-[3900px] h-[2300px] max-w-none"
                  draggable="false"
                />
                <CurrentPositionMarker {...getCurrentPosition()} />
              </div>
            </Draggable>
          </div>
        </div>
      )}
    </div>
  );
};
