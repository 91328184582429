import { isDevelopment } from "../utils/settings";
import { AppState, hexHeight, hexWidth } from "./State";

export function compareObjects(obj1: any, obj2: any): string {
  const diff: string[] = [];
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj2 === null || !(key in obj2)) {
        diff.push(`- ${key}: ${JSON.stringify(obj1[key], null, 2)}`);
      } else if (
        typeof obj1[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        const nestedDiff = compareObjects(obj1[key], obj2[key]);
        if (nestedDiff !== " No changes") {
          diff.push(`${key}:\n${nestedDiff}`);
        }
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        diff.push(
          ` ${key}: ${JSON.stringify(obj1[key], null, 2)} -> ${JSON.stringify(
            obj2[key],
            null,
            2
          )}`
        );
      }
    }
  }
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (obj1 === null || !(key in obj1)) {
        diff.push(`+ ${key}: ${JSON.stringify(obj2[key], null, 2)}`);
      }
    }
  }
  return diff.join("\n") || " No changes";
}

export const calculateNewWorldCoordinates = (
  state: AppState,
  newScale: number
) => {
  const { worldX, worldY, worldScale } = state;
  const centrX = worldX - window.innerWidth / 2;
  const centrY = worldY - window.innerHeight / 2;
  const zeroX = centrX / worldScale;
  const zeroY = centrY / worldScale;
  const newWorldX = zeroX * newScale + window.innerWidth / 2;
  const newWorldY = zeroY * newScale + window.innerHeight / 2;
  return { worldX: newWorldX, worldY: newWorldY };
};

const initCoordinates = isDevelopment
  ? [
      [0, 0],
      [5, 5],
      [5, -5],
      [-5, 5],
      [-5, -5],
    ]
  : [
      [0, 0],
      [5, 110],
      [-12, -100],
      [-70, -50],
      [-80, -50],
      [-115, -15],
      [-135, -5],
      [-155, 20],
      [-50, 70],
      [55, 100],
      [80, 65],
      [130, 20],
      [100, -20],
      [70, -70],
      [55, -105],
      [0, -115],
    ];

export const getInitialCoordinates = () => {
  const randomIndex = Math.floor(Math.random() * initCoordinates.length);
  const hexCoordinates = initCoordinates[randomIndex];
  return {
    worldX: -hexCoordinates[0] * hexWidth,
    worldY: -hexCoordinates[1] * hexHeight,
  };
};
