import { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import discord from "../Menu/discord.png";
import { ActionType } from "../State/State";
import { Hexagen } from "../types";
import { askAPI, RequestMethod } from "../utils/api";

interface RegeneratePopupProps {
  closeFn: () => void;
}

export const RegeneratePopup: React.FC<RegeneratePopupProps> = ({
  closeFn,
}) => {
  const { state, dispatch } = useContext(AppContext);
  const selectedHexInfo = state.selectedHexInfo;

  const [prompt, setPrompt] = useState(selectedHexInfo?.prompt || "");
  const [ignorePromptOverride, setIgnorePromptOverride] = useState(false);

  const maxPromptLength = 100;

  const handlePromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value.slice(0, maxPromptLength));
  };

  const handleIgnorePromptOverrideChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIgnorePromptOverride(event.target.checked);
  };

  const bgClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      closeFn();
    }
  };

  const selectedHexagon = state.selectedHexInfo;
  const player = state.player;
  const selectedHexCoord = state.selectedHexCoord;

  if (!selectedHexagon || !player) {
    return null;
  }

  const canRegenerate = player.points >= (ignorePromptOverride ? 240 : 120);

  const btnClass =
    "select-none cursor-pointer button w-20 p-1 px-2 text-center text-xs mb-4 ";

  const regenerateHex = async () => {
    if (selectedHexCoord) {
      dispatch({
        type: ActionType.ADD_REGENERATING_NOW_HEX,
        x: selectedHexCoord.x,
        y: selectedHexCoord.y,
      });

      closeFn();

      await askAPI<Hexagen>("/api/hexagen/regenerate", RequestMethod.POST, {
        x: selectedHexCoord.x,
        y: selectedHexCoord.y,
        prompt,
        ignorePromptOverride,
      });
    }
  };

  return (
    <div
      onClick={bgClickHandler}
      className="absolute top-0 bottom-0 left-0 right-0"
    >
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-55"></div>
      <div className="antique absolute top-2 left-2 right-2 p-6 pb-2">
        <div onClick={closeFn} className={btnClass}>
          {"<<< "}back
        </div>
        <div className="text-lg mt-6">Hexagon Regeneration</div>
        <div className="mb-4  text-xs">
          x: {selectedHexagon.x} | y:{selectedHexagon.y}
        </div>
        <div className="mb-4">
          <div className="font-bold mb-2">Edit prompt:</div>
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            rows={3}
            className="w-full resize-none p-1 px-2 focus:outline-none"
          ></textarea>
          <div className="flex items-center gap-2 mb-2">
            <input
              type="checkbox"
              checked={ignorePromptOverride}
              onChange={handleIgnorePromptOverrideChange}
            />
            <span className="text-xs">
              Extra Creativity Mode (additional -120 points)
            </span>
          </div>
          <div className="text-xs mb-2">
            {maxPromptLength - prompt.length} letters left
          </div>
        </div>
        <div>
          Upon regeneration, it will be better integrated into the surrounding
          environment and will take up to 20% more space.
        </div>
        <div className="mt-3 mb-4">
          This will cost <b>{ignorePromptOverride ? 240 : 120} points.</b>
        </div>

        <div
          className={
            btnClass + (canRegenerate ? "" : "opacity-45 pointer-events-none")
          }
          onClick={regenerateHex}
        >
          Proceed!
        </div>

        {!canRegenerate ? (
          <div className="text-xs mb-6">
            <div>Unfortunately, you don't have enough points.</div>
            <div>
              You can earn your first points in our{" "}
              <a
                href="https://discord.gg/yqkuuXnt2N"
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={discord}
                  alt=""
                  width={15}
                  className="inline-block mr-1 mt-[-3px]"
                />
                Discord
              </a>
              .
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
