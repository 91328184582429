import { useContext, useEffect } from "react";
import useAsyncEffect from "use-async-effect";
import "./App.scss";
import { MenuBottom } from "./Menu/MenuBottom";
import { MenuTop } from "./Menu/MenuTop";
import { HexGeneratePopup } from "./Popups/HexGeneratePopup";
import { HexInfoPopup } from "./Popups/HexInfoPopup";
import { LoginPopup } from "./Popups/LoginPopup";
import { PlayerProfilePopup } from "./Popups/PlayerProfilePopup";
import { ActionType } from "./State/State";
import { SystemMessage } from "./SystemMessage/SystemMessage";
import { World } from "./World/World";
import { RequestMethod, askAPI } from "./utils/api";
import { Statistic } from "./types";
import { PlayerInfoPopup } from "./Popups/PlayerInfoPopup";
import { MenuPopup } from "./Menu/MenuPopup";
import { AppContext } from "./State/Context";
import { AboutGamePopup } from "./Popups/AboutGamePopup";
import { ZoomController } from "./Zoom/Zoom";
import { GeneratedPopupAward } from "./Popups/GeneratedAwardPopup";
import { MiniMap } from "./Map/Map";
import { GlobalChatPopup } from "./Popups/GlobalChatPopup";
import { RedeemPopup } from "./Popups/RedeemPopup";
import { NotificationsPopup } from "./Popups/NotificationsPopup";
import { MenuLeft } from "./Menu/MenuLeft";
import { RarityRatingPopup } from "./Popups/RarityRatingPopup";
import { NFTPopup } from "./Popups/NFTPopup";
import { NFTConfirmMintingPopup } from "./Popups/NFTConfirmMintingPopup";

function App() {
  const { state, dispatch } = useContext(AppContext);

  // Add popstate event listener
  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useAsyncEffect(async () => {
    const fetchStatistic = async () => {
      const statistic = await askAPI<Statistic>(
        "/api/statistic",
        RequestMethod.GET
      );

      if (statistic) {
        dispatch({ type: ActionType.UPDATE_STATISTIC, statistic });

        dispatch({
          type: ActionType.UPDATE_NOTIFICATIONS,
          notifications: statistic.notifications || [],
        });

        dispatch({
          type: ActionType.UPDATE_PLAYER,
          player: statistic.player || null,
        });
      }
    };

    const updateData = async () => {
      try {
        await fetchStatistic();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const intervalId = setInterval(updateData, 10000);
    updateData();

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App p-2">
      <MenuTop />
      <World />
      <ZoomController />
      <MenuLeft />
      <MiniMap />
      <MenuBottom />
      {state.isMostViewedPopupOpen && <RarityRatingPopup />}
      {state.isHexInfoPopupOpen && <HexInfoPopup />}
      <PlayerProfilePopup />
      <LoginPopup />
      <HexGeneratePopup />
      {state.isNftPopupOpen && <NFTPopup />}
      {state.isPlayerInfoPopupOpen && <PlayerInfoPopup />}
      {state.isAboutGamePopupOpen && <AboutGamePopup />}
      <GeneratedPopupAward />
      {state.isRedeemPopupOpen && <RedeemPopup />}
      <SystemMessage />
      {state.isMenuPopupOpen && <MenuPopup />}
      {state.isGlobalChatPopupOpen && <GlobalChatPopup />}
      {/* {state.isNFTAlertPopupOpen && <ScamAlertPopup />} */}
      {state.isNotificationsPopupOpen && <NotificationsPopup />}
      {state.isNFTConfirmMintingPopupOpen && <NFTConfirmMintingPopup />}
    </div>
  );
}

export default App;
