// - [ ] Create modal popup component with warning banner
// - [ ] Add experimental feature warning text with highlighted styling
// - [ ] Implement Discord support link button
//   - Include icon
//   - Link to specific NFT support channel
// - [ ] Create primary "Start Minting" button
// - [ ] Design loading state
// - [ ] Ask player not to close the page
// - [ ] Disable all interactions during minting

import { useContext, useState, useEffect } from "react";
import { BasicPopup } from "./BasicPopup";
import { AppContext } from "../State/Context";
import { img } from "../utils/settings";
import { RiNftFill } from "react-icons/ri";
import { RequestMethod } from "../utils/api";
import { askAPI } from "../utils/api";
import { ActionType } from "../State/State";

export const NFTConfirmMintingPopup = () => {
  const { state, dispatch } = useContext(AppContext);
  const [mintingPhrases, setMintingPhrases] = useState<string[]>([
    "",
    "",
    mintFrases[0],
  ]);
  const [phrasesCount, setPhrasesCount] = useState<number>(1);

  const [isMinting, setIsMinting] = useState<boolean>(false);

  const [nftID, setNftID] = useState<string | null>(null);

  const onMint = async () => {
    setIsMinting(true);

    const res = await askAPI<{ tokenId: string }>(
      "/api/nft/mint",
      RequestMethod.POST,
      {
        x: state.selectedHexInfo?.x,
        y: state.selectedHexInfo?.y,
      }
    );

    console.log("res", res);

    setNftID(res?.tokenId || null);
    setIsMinting(false);

    if (res?.tokenId) {
      dispatch({
        type: ActionType.TOGGLE_HEX_INFO_POPUP,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMintingPhrases((prev) => {
        let newPhrase;
        do {
          newPhrase = mintFrases[Math.floor(Math.random() * mintFrases.length)];
        } while (prev.includes(newPhrase));

        return [...prev.slice(1), newPhrase];
      });

      setPhrasesCount((prev) => Math.min(prev + 1, 3));
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const onClose = () => {
    if (isMinting) {
      return;
    }
    dispatch({ type: ActionType.TOGGLE_NFT_CONFIRM_MINTING_POPUP });
  };

  return (
    <BasicPopup onClose={onClose}>
      <div className="">
        <div className="text-[50px] h-[50px] text-center leading-normal mb-4">
          ⚠️
        </div>
        <div className="text-lg font-bold text-center mb-4">
          Please pay attention!
        </div>
        <div className="text-sm mb-4 px-4">
          Hexagen.World is an indie project developed by a single person, and
          minting an <b>NFT is an experimental feature</b> — things might not go
          as planned.
        </div>
        <div className="text-sm mb-4 px-4">
          The minting process usually takes under a minute. <br />
          🙏 Please keep this pop-up open until it’s finished.
        </div>
        <div className="flex flex-col items-center mt-8">
          <div className="text-lg font-bold mb-2">NFT data:</div>
          <div className="flex items-center gap-4">
            <img
              src={img(
                `x${state.selectedHexInfo?.x}y${state.selectedHexInfo?.y}@big.png?v=${state.selectedHexInfo?.regeneratedAt}`
              )}
              alt="NFT"
              className="w-[200px] h-[200px]"
            />
            <div className="text-lg">
              x: {state.selectedHexInfo?.x}
              <br />
              y: {state.selectedHexInfo?.y}
            </div>
          </div>
        </div>
        <div className="text-xs mb-4 text-center mt-2">
          For the wallet:{" "}
          <div className="text-xs">
            <a
              href="https://polygonscan.com/address/0x5c55B7f84d5cA7Cb973f3825740CB4f6Cd646ceE"
              target="_blank"
              rel="noreferrer"
            >
              0x5c55B7f84d5cA7Cb973f3825740CB4f6Cd646ceE
            </a>
          </div>
        </div>
        <div className="text-xs mb-4 text-center">
          Contract Address:{" "}
          <div className="text-xs">
            <a
              href="https://polygonscan.com/token/0x252B85B3fDC807D2eE0dF1c26d5E4d2D4e7A57Dd"
              target="_blank"
              rel="noreferrer"
            >
              0x252B85B3fDC807D2eE0dF1c26d5E4d2D4e7A57Dd
            </a>
          </div>
        </div>

        {isMinting ? (
          <div className="flex flex-col gap-1 mt-10 mb-12 px-6">
            {mintingPhrases.map((phrase, index) => (
              <div
                key={index}
                className="text-xs"
                style={{
                  opacity:
                    index >= 3 - phrasesCount ? (index === 2 ? 1 : 0.5) : 0,
                  height: index >= 3 - phrasesCount ? "auto" : 0,
                  overflow: "hidden",
                }}
              >
                {phrase}
              </div>
            ))}
          </div>
        ) : (
          <div>
            {nftID ? (
              <div className="text-center">
                <div>
                  <div className="text-[20px] mt-10 mb-2">
                    Congratulations 🥳
                  </div>
                  NFT has been successfully minted.
                </div>
                <div className="mb-6">
                  View on{" "}
                  <img
                    src="/opensea-logo.png"
                    alt="OpenSea"
                    className="w-4 h-4 mr-1 inline"
                  />
                  <a
                    href={`https://opensea.io/assets/matic/0x252b85b3fdc807d2ee0df1c26d5e4d2d4e7a57dd/${nftID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    OpenSea
                  </a>
                </div>
                <div className="my-6 mb-12 text-xs">
                  Thank you for being part of this experiment 💙
                </div>
              </div>
            ) : (
              <div className="text-center mt-10 mb-6">
                <button
                  className="button button-blue mb-1 px-2 py-1 ml-1 text-xs"
                  onClick={onMint}
                >
                  <RiNftFill className="mr-1 inline text-[14px] translate-y-[-1px]" />
                  Mint NFT NOW!
                </button>

                <div className="text-xs mt-4 opacity-60">
                  Minting NFT costs 240 creative points.
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </BasicPopup>
  );
};

const mintFrases = [
  "Minting in progress...",
  "Waiting for the blockchain to confirm...",
  "Finalizing the transaction...",
  "Almost there...",
  "Minting is almost done...",
  "Preparing your NFTs...",
  "Synchronizing with the blockchain...",
  "Almost ready...",
  "Verifying transaction...",
  "Creating a unique token...",
  "Please wait, processing...",
  "Your NFT is almost ready...",
  "Adding final touches...",
  "You'll see your NFT soon...",
  "Checking data on the blockchain...",
  "Completing the minting process...",
  "Your NFT will be available soon...",
  "npm install --save your-nft...",
  "git commit -m 'Adding your NFT to history'...",
  "Waiting for blockchain validators to wake up...",
  "Converting your art into magical blockchain numbers...",
  "Searching for gas fees under the couch...",
  "Teaching smart contract new tricks...",
  "Convincing miners to include your transaction...",
  "Performing blockchain magic...",
  "Summoning the NFT spirits...",
  "Running blockchain.exe...",
  "Executing mint.sh...",
  "sudo mint --force your-nft...",
  "Compiling token metadata...",
  "Deploying to layer 2...",
  "Initializing web3.mint()...",
  "Checking if blockchain is not on coffee break...",
  "Converting pixels to wei...",
  "Spinning up the blockchain hamster wheel...",
  "Waiting for consensus (they're arguing)...",
  "Calculating gas fees (bring your wallet)...",
  "Asking blockchain nicely to accept your NFT...",
  "Running blockchain.indexOf(your-nft)...",
  "while(minting) { please.wait(); }...",
  "try { mint() } catch(expensive) { cry() }...",
  "SELECT * FROM blockchain WHERE nft = yours...",
  "git push --force your-wallet...",
  "npm audit fix --force your-transaction...",
  "Downloading more RAM for blockchain...",
  "mkdir -p /path/to/your/wealth...",
  "Updating blockchain dependencies...",
  "yarn add @blockchain/patience...",
  "Reticulating NFT splines...",
  "docker run -it --name your-wealth blockchain...",
  "Running blockchain in production mode...",
  "Spawning new blockchain worker...",
  "import { Success } from 'wishful-thinking'...",
  "export const yourMoney = undefined...",
  "blockchain.sort(() => Math.random())...",
  "Converting callback hell to async/await...",
  "Updating blockchain registry...",
  "rm -rf /node_modules && npm i your-money...",
  "pip install more-patience...",
  "composer require web3/optimism...",
  "cargo build --release your-hopes...",
  "go run blockchain.go || panic()...",
  "rails new crypto_fortune...",
  "spring boot loading your NFT...",
  "kubectl apply -f your-wealth.yaml...",
  "terraform plan your-future...",
  "ansible-playbook deploy-dreams.yml...",
  "gradle buildOptimism --parallel...",
  "mvn clean install your-nft...",
  "pod install CryptoSDK...",
  "brew install more-money...",
  "apt-get update && apt-get upgrade wealth...",
  "systemctl start blockchain...",
  "service nft-dreams restart...",
  "pm2 scale wealth-generator +infinity...",
  "docker-compose up your-fortune...",
  "nginx -t && nginx -s reload wealth...",
  "webpack --mode=production your-nft...",
  "babel --presets=money your-future...",
  "eslint --fix your-wallet...",
  "prettier --write your-fortune...",
  "tsc --noEmit your-wealth...",
];
