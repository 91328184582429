import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  CSSProperties,
} from "react";
import { AppContext } from "../State/Context";
import { Hexagen } from "../types";
import { ActionType } from "../State/State";
import { hexHeight, hexWidth } from "../State/State";
import { img } from "../utils/settings";

export const MenuLeft: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  // Show menu on screens wider than 600px
  const [isVisible, setIsVisible] = useState<boolean>(
    typeof window !== "undefined" && window.innerWidth > 600
  );

  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Refs for drag and scroll functionality
  const topSectionRef = useRef<HTMLDivElement>(null);
  const bottomSectionRef = useRef<HTMLDivElement>(null);
  const dragStartY = useRef<number>(0);
  const scrollStartY = useRef<number>(0);
  const dragStartTime = useRef<number>(0);
  const activeRef = useRef<React.RefObject<HTMLDivElement> | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && activeRef.current?.current) {
        e.preventDefault();
        const delta = e.clientY - dragStartY.current;
        const newPosition = scrollStartY.current + delta;

        // Limit scrolling within content boundaries
        const parentHeight =
          activeRef.current.current.parentElement?.clientHeight || 0;
        const contentHeight = activeRef.current.current.scrollHeight || 0;

        const maxScroll = 0;
        const minScroll = Math.min(0, parentHeight - contentHeight);

        const clampedPosition = Math.max(
          minScroll,
          Math.min(maxScroll, newPosition)
        );
        activeRef.current.current.style.transform = `translateY(${clampedPosition}px)`;
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      activeRef.current = null;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  if (!isVisible) return null;

  const topPlayers = state.statistic?.richestPlayers || [];
  const topSectionHex = state.statistic?.newestHexagons || [];
  const bottomSectionHex = state.statistic?.topUpvotedHexagons || [];

  const getHexImageUrl = (hex: Hexagen) => img(`x${hex.x}y${hex.y}.png`);

  const handlePlayerClick = (uid: string) => {
    dispatch({ type: ActionType.SELECT_PLAYER, uid });
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
  };

  const onClickOnHex = (hexagen: Hexagen) => {
    const dragDuration = Date.now() - dragStartTime.current;
    // Prevent hex selection if user was dragging
    if (dragDuration > 500) return;

    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -hexagen.x * hexWidth * state.worldScale,
      y: -(hexagen.y - 2) * hexHeight * state.worldScale,
      logggable: true,
    });
  };

  const handleMouseEnter = (hex: Hexagen) => {
    setPreviewImage(getHexImageUrl(hex));
  };

  const handleMouseLeave = () => {
    setPreviewImage(null);
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement>,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    if (ref.current) {
      setIsDragging(true);
      activeRef.current = ref;
      dragStartY.current = e.clientY;
      dragStartTime.current = Date.now();

      const transform = ref.current.style.transform || "translateY(0px)";
      const currentY = parseInt(transform.replace(/[^\d-]/g, "")) || 0;
      scrollStartY.current = currentY;
    }
  };

  const scrollContainerStyle: CSSProperties = {
    cursor: isDragging ? "grabbing" : "grab",
    height: "100%",
    position: "relative",
    transition: isDragging ? "none" : "transform 0.3s ease-out",
  };

  const sectionStyle: CSSProperties = {
    touchAction: "none",
    height: "50%",
    overflow: "hidden",
  };

  return (
    <>
      {/* Top players ranking */}
      {topPlayers.length > 0 && (
        <div
          className="fixed user-select-none text-xs"
          style={{
            top: "50px",
            left: "66px",
          }}
        >
          <ul className="flex flex-col">
            {topPlayers.map((player, i) => (
              <li
                key={player.uid || i}
                className="bg-black text-amber-50 opacity-90 bg-opacity-70 px-2 pr-4 py-1 w-fit flex items-center"
              >
                {i === 0 && <span className="text-[16px] pr-2">👑 </span>}
                {i === 1 && <span className="text-[16px] pr-2">✨ </span>}
                {i === 2 && <span className="text-[16px] pr-2">💛 </span>}

                <span
                  className="cursor-pointer hover:underline"
                  onClick={() => player.uid && handlePlayerClick(player.uid)}
                >
                  {player.name}
                </span>
                <span className="text-[10px] pl-1">/{player.points}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Hex preview overlay */}
      {previewImage && (
        <div className="fixed inset-0 leftmenu-bg visible bg-black bg-opacity-70 flex items-center justify-center transition-all duration-300 ease-in-out">
          <img
            src={previewImage + `?${Date.now()}`}
            alt="Preview"
            className="w-[512px] h-[512px] object-contain transition-transform duration-300 ease-in-out"
            onMouseLeave={handleMouseLeave}
          />
        </div>
      )}

      {/* Side menu with newest and top rated hexes */}
      <div
        className="user-select-none fixed top-0 left-0 h-full bg-black bg-opacity-80 flex flex-col text-amber-50"
        style={{ width: "60px", top: "44px", bottom: "80px" }}
      >
        {/* New hexes section */}
        <div
          className="flex-1 border-b border-black flex flex-col items-center"
          style={sectionStyle}
        >
          <div className="bg-black z-10 p-1 px-2 w-full text-center">new🔥</div>
          <div
            className="vertical-scroll pb-10"
            ref={topSectionRef}
            onMouseDown={(e) => handleMouseDown(e, topSectionRef)}
            style={scrollContainerStyle}
          >
            {topSectionHex.map((hex, i) => (
              <img
                key={hex.uid || i}
                src={`/api/slow-images/x${hex.x}y${hex.y}.png`}
                alt={`recent-hex-${hex.uid}`}
                className="w-full h-auto mb-1 cursor-pointer animate-from-fade-on-appear"
                onClick={() => !isDragging && onClickOnHex(hex)}
                onMouseEnter={() => !isDragging && handleMouseEnter(hex)}
                onMouseLeave={handleMouseLeave}
                draggable={false}
              />
            ))}
            <div className="h-10" />
          </div>
        </div>

        {/* Top rated hexes section */}
        <div
          className="flex-1 p-1 pt-0 flex flex-col items-center border-t border-black border-solid"
          style={sectionStyle}
        >
          <div className="pointer-events-none py-2 pl-1 leading-[12px] bg-black z-10">
            week <br />
            top 💪
          </div>
          <div
            className="vertical-scroll"
            ref={bottomSectionRef}
            onMouseDown={(e) => handleMouseDown(e, bottomSectionRef)}
            style={scrollContainerStyle}
          >
            {bottomSectionHex.map((hex, i) => (
              <img
                key={hex.uid || i}
                src={getHexImageUrl(hex)}
                alt={`new-hex-${hex.uid}`}
                className="w-full h-auto mb-1 cursor-pointer animate-from-fade-on-appear"
                onClick={() => !isDragging && onClickOnHex(hex)}
                onMouseEnter={() => !isDragging && handleMouseEnter(hex)}
                onMouseLeave={handleMouseLeave}
                draggable={false}
              />
            ))}
            <div className="h-[200px]" />
          </div>
        </div>
      </div>
    </>
  );
};
