import { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";
import useAsyncEffect from "use-async-effect";
import { askAPI, RequestMethod } from "../utils/api";
import { Comment } from "../Comments/Comments";
import { img } from "../utils/settings";
import { getTimeAgo } from "../utils/misc";
import { hexWidth, hexHeight } from "../State/State";

export const GlobalChatPopup: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP });
  };

  const [comments, setComments] = useState<Comment[]>([]);

  const clickOnPlayer = (uid: string) => {
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
      dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP });
    }
  };

  const onClickOnHex = (x: number, y: number) => {
    dispatch({ type: ActionType.SELECT_HEX, x, y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -x * hexWidth * state.worldScale,
      y: -(y - 2) * hexHeight * state.worldScale,
    });
  };

  useAsyncEffect(async () => {
    const commentsFromAPI = await askAPI<Comment[]>(
      "/api/comments/globalAll",
      RequestMethod.GET
    );

    commentsFromAPI && setComments(commentsFromAPI);
  }, []);
  return (
    <BasicPopup onClose={closePopup}>
      <div className="font-bold text-lg mb-3">Last Comments </div>
      <div className="comments">
        {comments.map((comment) => (
          <div key={comment.id} className="flex mb-6">
            <div
              onClick={() => onClickOnHex(comment.x, comment.y)}
              className="cursor-pointer flex-shrink-0"
            >
              <img
                src={img(`x${comment.x}y${comment.y}@big.png`)}
                alt="hexagen"
                className="w-40 h-40"
              />
            </div>
            <div className="pl-4 pt-8">
              <div
                onClick={() => clickOnPlayer(comment.author)}
                className="text-xs opacity-60 cursor-pointer"
              >
                {comment.authorName}
              </div>
              <div className="comment-text">{comment.text}</div>
              <div className="text-xs opacity-40">
                {getTimeAgo(comment.date)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </BasicPopup>
  );
};
