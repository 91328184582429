import axios from "axios";
import { SystemMessage } from "../types";

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
}

export const askAPI = async <T>(
  url: string,
  method: RequestMethod,
  body?: any
): Promise<T | null> => {
  try {
    if (method === RequestMethod.GET) {
      const response = await axios.get<T>(url, { params: body });
      return checkForSystemMessage(response.data);
    } else if (method === RequestMethod.POST) {
      const response = await axios.post<T>(url, body);
      return checkForSystemMessage(response.data);
    }
    return null;
  } catch (error) {
    // Handle axios errors
    if (axios.isAxiosError(error)) {
      const errorMessage: SystemMessage = {
        systemMessage: "error",
        text: error.response?.data?.message || "api error",
      };
      showSystemMessage(errorMessage);
    } else {
      // Handle unexpected errors
      const errorMessage: SystemMessage = {
        systemMessage: "error",
        text: "unknown error",
      };
      showSystemMessage(errorMessage);
    }
    return null;
  }
};

const checkForSystemMessage = (responseData: any) => {
  if (responseData.systemMessage) {
    systemMessageSubsctriber(responseData);
    return responseData.data;
  } else {
    return responseData;
  }
};

let systemMessageSubsctriber: (message: SystemMessage) => void = () => {};
export const subscribeToSystemMessages = (
  callback: (message: SystemMessage) => void
) => {
  systemMessageSubsctriber = callback;
};

export const showSystemMessage = (message: SystemMessage) => {
  systemMessageSubsctriber(message);
};
