import { Hexagen } from "../types";

//
// DUPLICATE IN THE BACKEND /utils/rarity.ts
//

export type RarityLevel =
  | "common"
  | "uncommon"
  | "rare"
  | "epic"
  | "legendary"
  | "mythic"
  | "divine"
  | "cosmic"
  | "transcendent"
  | "ultimate";

export const rarityLevels: RarityLevel[] = [
  "common",
  "uncommon",
  "rare",
  "epic",
  "legendary",
  "mythic",
  "divine",
  "cosmic",
  "transcendent",
  "ultimate",
];

export const getRarityEmoji = (rarity: RarityLevel) => {
  const emoji = ["🍂", "🌿", "💎", "⚔️", "🏆", "🦄", "👑", "🚀", "🌟", "🔥"];
  return emoji[rarityLevels.indexOf(rarity)];
};

export const getHexagenRarityScore = (hexagen: Hexagen) => {
  const createdAt = new Date(hexagen.createdAt);
  const daysSinceCreation = Math.floor(
    (Date.now() - createdAt.getTime()) / (1000 * 60 * 60 * 24)
  );

  const { upvotes, views, points } = hexagen;

  const score =
    points / 2 +
    Math.min(upvotes, 50) +
    Math.min(views * 0.1, 50) +
    Math.min(daysSinceCreation / 5, 50);

  return score;
};

export interface RarityInfo {
  rarity: RarityLevel;
  emoji: string;
  score: number;
}

export const getHexagenRarity = (hexagen: Hexagen): RarityInfo => {
  const score = Math.max(0, getHexagenRarityScore(hexagen));

  const rarityThresholds = [
    { threshold: 100, rarity: "ultimate" },
    { threshold: 90, rarity: "transcendent" },
    { threshold: 80, rarity: "cosmic" },
    { threshold: 70, rarity: "divine" },
    { threshold: 60, rarity: "mythic" },
    { threshold: 50, rarity: "legendary" },
    { threshold: 40, rarity: "epic" },
    { threshold: 30, rarity: "rare" },
    { threshold: 20, rarity: "uncommon" },
    { threshold: 0, rarity: "common" },
  ] as const;

  // Find first matching threshold
  const { rarity } = rarityThresholds.find(
    ({ threshold }) => score >= threshold
  ) ?? { rarity: "common" };

  return {
    rarity,
    emoji: getRarityEmoji(rarity),
    score,
  };
};
