import { useContext, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AppContext } from "../State/Context";
import { ActionType } from "../State/State";
import { hexWidth, hexHeight } from "../State/State";
import { RequestMethod, askAPI } from "../utils/api";
import { Hexagen, Player } from "../types";
import { BasicPopup } from "./BasicPopup";
import { getRarityEmoji } from "../utils/rarity";
import { HexagonCard } from "./RarityRatingPopup";

export const PlayerInfoPopup: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const [playerInfo, setPlayerInfo] = useState<Player | null>(null);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("nfts");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [isPlayerHasNFTs, setIsPlayerHasNFTs] = useState(false);
  const [rarityOrder, setRarityOrder] = useState<"asc" | "desc">("desc");
  const [nftsOrder, setNftsOrder] = useState<"asc" | "desc">("desc");
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    window.history.pushState(null, "", "/");
  };

  const fetchPlayerInfo = async () => {
    const uid = state.selectedPlayerUID;
    if (uid) {
      playerInfo && setPlayerInfo({ ...playerInfo, hexagens: [] });

      const url = `/player/${uid}`;
      window.history.pushState(null, "", url);

      if (sortBy === "nfts") {
        const nftsResponse = await askAPI<Hexagen[]>(
          `/api/player/nfts/${uid}/${page}/${nftsOrder}`,
          RequestMethod.GET
        );

        if (nftsResponse === null) return;

        const playerInfoUpdated = await askAPI<Player>(
          `/api/player/info/${uid}/${page}`,
          RequestMethod.GET
        );

        if (playerInfoUpdated === null) return;

        if (nftsResponse.length === 0) {
          setIsPlayerHasNFTs(false);
          setSortBy("rarity");
          setPlayerInfo({
            ...playerInfoUpdated,
            nftsNumber: 0,
          });
        } else {
          setIsPlayerHasNFTs(true);
          setPlayerInfo({
            ...playerInfoUpdated,
            hexagens: nftsResponse,
            nftsNumber: nftsResponse.length,
          });
        }
        return;
      }

      if (sortBy === "rarity") {
        const playerInfoUpdated = await askAPI<Player>(
          `/api/player/info/${uid}/${page}?sortBy=${sortBy}&order=${order}`,
          RequestMethod.GET
        );
        const mostRareHexagens = await askAPI<Hexagen[]>(
          `/api/player/most-rare/${uid}/${page}/${
            rarityOrder === "asc" ? true : false
          }`,
          RequestMethod.GET
        );

        if (!mostRareHexagens || !playerInfoUpdated) return;

        setPlayerInfo({
          ...playerInfoUpdated,
          hexagens: mostRareHexagens,
        });
        return;
      }

      const playerInfoUpdated = await askAPI<Player>(
        `/api/player/info/${uid}/${page}?sortBy=${sortBy}&order=${order}`,
        RequestMethod.GET
      );

      playerInfoUpdated &&
        setPlayerInfo({
          ...playerInfoUpdated,
          hexagens: playerInfoUpdated.hexagens as Hexagen[],
          hexagensNumber: playerInfoUpdated.hexagensNumber,
        });
    }
  };

  useAsyncEffect(fetchPlayerInfo, [
    page,
    sortBy,
    order,
    rarityOrder,
    nftsOrder,
  ]);

  const onClickOnHex = (hexagen: Hexagen) => {
    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -hexagen.x * hexWidth * state.worldScale,
      y: -(hexagen.y - 2) * hexHeight * state.worldScale,
      logggable: true,
    });
  };

  const handleSortChange = (newSortBy: string) => {
    if (newSortBy === sortBy) {
      if (sortBy === "rarity") {
        setRarityOrder(rarityOrder === "asc" ? "desc" : "asc");
      } else if (sortBy === "nfts") {
        setNftsOrder(nftsOrder === "asc" ? "desc" : "asc");
      } else {
        setOrder(order === "asc" ? "desc" : "asc");
      }
    } else {
      setSortBy(newSortBy);
      setPage(0);
    }
  };

  const hexagens = playerInfo ? playerInfo.hexagens : [];

  return (
    <BasicPopup onClose={closePopup}>
      <div className="overflow-hidden">
        {playerInfo ? (
          <>
            <div className="flex justify-center text-[30px] mt-4 opacity-90">
              ⬢
            </div>
            <div className="text-4xl mt-3 mb-6 text-center">
              {playerInfo.name} <br />
              <div className="mt-1 text-2xl text-gray-800 ">
                {playerInfo.top3Rarities.map(getRarityEmoji).join("")}
              </div>
              <div className="mb-4 mt-2 text-xs opacity-60 text-center">
                {playerInfo.hexagensNumber} hexagens & {playerInfo.nftsNumber}{" "}
                NFTs
              </div>
            </div>
            <div className="flex justify-center">
              <div className="flex items-center">
                <div className="text-[30px] mr-2">{playerInfo.points}</div>
                <div className="flex flex-col justify-center leading-tight text-xs">
                  <span className="text-left">creative</span>
                  <span className="text-left">points</span>
                </div>
              </div>
            </div>
            <div className="text-xs opacity-60 flex justify-center">
              Collection:
            </div>
            <div className="mb-4 select-none text-xs flex justify-center">
              {isPlayerHasNFTs && (
                <>
                  <span
                    onClick={() => handleSortChange("nfts")}
                    className={`cursor-pointer underline ${
                      sortBy === "nfts" ? "font-bold" : ""
                    }`}
                  >
                    <span className="underline">NFTs</span>
                    <span className="pl-1">
                      {nftsOrder === "asc" ? "▼" : "▲"}
                    </span>
                  </span>
                  <span className="mx-2 text-sm">|</span>
                </>
              )}
              <span
                onClick={() => handleSortChange("rarity")}
                className={`cursor-pointer underline ${
                  sortBy === "rarity" ? "font-bold" : ""
                }`}
              >
                Rarity
              </span>
              <span className="pl-1">{rarityOrder === "asc" ? "▲" : "▼"}</span>
              <span className="mx-2 text-sm">|</span>
              <span
                onClick={() => handleSortChange("createdAt")}
                className={`cursor-pointer ${
                  sortBy === "createdAt" ? "font-bold" : ""
                }`}
              >
                <span className="underline">Date</span>
                <span className="pl-1">{order === "asc" ? "▼" : "▲"}</span>
              </span>
            </div>
            <div className="grid grid-cols-1 gap-4 px-4">
              {hexagens.map((hexagen: Hexagen, i: number) => (
                <HexagonCard key={i} hexagen={hexagen} onClick={onClickOnHex} />
              ))}
            </div>

            <div className="my-6 flex items-center justify-center select-none text-lg text-center h-10">
              <div
                onClick={() => page > 0 && setPage(Math.max(0, page - 1))}
                className={`px-2 py-1 cursor-pointer flex items-center translate-y-[-3px] ${
                  page === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ◀
              </div>
              <span className="text-xs flex items-center mx-2 text-center">
                Page {page + 1} from {Math.ceil(playerInfo.hexagensNumber / 16)}{" "}
              </span>
              <div
                onClick={() => hexagens.length >= 16 && setPage(page + 1)}
                className={`px-2 py-1 cursor-pointer flex items-center translate-y-[-3px] ${
                  hexagens.length < 16 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                ▶
              </div>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </BasicPopup>
  );
};
